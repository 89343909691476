
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PageNotFound extends Vue {
  //   @Prop({ default: true, required: false })
  //   canGoHome!: boolean;
}
